/* body {
  text-align: center;
  justify-content: center;
  background: linear-gradient(90deg, #e5ddd4 50%, #bea48d 50%);
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap");


header {
  background-color: #f3ede9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 120px;
  width: 100%;
  z-index: 3;
}

nav>p {
  font-family: "Libre Franklin", sans-serif;  font-size: 14px;
  font-weight: 400;
  color: rgb(74, 74, 74);
  line-height: 25.2px;
  text-transform: uppercase;
  letter-spacing: 2px;

}

p {
  font-family: "Libre Franklin", sans-serif;
  list-style: none;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: rgb(74, 74, 74);
  line-height: 25.2px;
}

ul,
.ul-p {
  font-family: "helvetica-w01-light", "helvetica-w02-light", sans-serif;
  list-style: none;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  color: rgb(74, 74, 74);
  line-height: 25.2px;
  padding: 0;
  margin: 0;
  padding-top: 15px;
}

li {

  padding: 7px;
  margin-top: -12px;
  display: inline;
  font-size: 15px;
}

.nav-p {
  font-size: 10px;
  margin: 0;
  color: #553b27;

}

.intro {
  padding: 30px;
  margin: 60px;
}

.skills {
  position: left;
  width: 65vh;
  padding: 30px;
  margin: 60px;
}

body {
  
  margin-top: 100px;
}

.first {
  display: flex;
}

.projects {
  background-color: #cbbfac;
  padding: 20px;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience {
  display: flex;
}

.jobs,
.jobs>h1 {
  background-color: #baaca0;
  margin-top: 0;
  padding: 35px;
}

.contact {
  display: flex;
  margin-right: 15%;
  margin-top: 35px;
  justify-content: center;
  align-items: center;
  padding-bottom: 105px;
}

.contact>h1 {
  margin-left: 40vh;
  font-size: 55px;
}

.contacts>li {
  display: block;
  margin-left: 80px;
  text-align: left;
  width: 250px;
  font-size: 11px;
}

a {

  text-decoration: none;
  color: #553b27;
}

h1 {
  font-size: 55px;
  
  
}

.skills {
  justify-content: center;
}

.logos {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
  width: 535px;
}

.logos>li {
  padding: 20px;
  align-items: center;
  
}

.hello {
  padding-top: 20px;
}




@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap");

header {
  background-color: #f3ede9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 18vh;
  width: 100%;
  z-index: 3;
  overflow: hidden;
}

ul,
.ul-p {
  font-family: "Libre Franklin", sans-serif;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

li {
  text-transform: uppercase;
  padding: 7px;
  font-size: 1.5vh;
}

.logo-cp {
  width: 50%;
}

body {
  text-align: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #553b27;
} */

/* COMING SOON PAGE */ 
@font-face {
  font-family: "Functional";
  src: url("./fonts/FUNCTIONDISPLAY_REGULAR.TTF");
}

@font-face {
  font-family: "Functional-Outline";
  src: url("./fonts/FuturaDisplayPOT-Outline.otf");
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
}
body{
  background-color: black;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 0 1rem;
}

.loading-text {
  font-family: 'Functional-outline', sans-serif;
  margin-top: 1rem;
  font-size: 50px;
  color: white;
  text-align: center;
}

.white-logo {
  color: #ffbae4;
    font-family: 'Functional-outline', sans-serif;
      margin-top: 1rem;
  text-align: center;
}

/* Mobile-responsive adjustments */
@media (max-width: 768px) {
  .loading-text {
    font-size: 2rem;
    /* Smaller font size for tablets and mobile */
  }
}

@media (max-width: 480px) {
  .loading-text {
    font-size: 1.5rem;
    /* Even smaller font size for very small screens */
  }
}

img {
  height: 50px;
}
.svg-frame {
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-frame svg {
  position: absolute;
  transition: .5s;
  z-index: calc(1 - (0.2 * var(--j)));
  transform-origin: center;
  width: 344px;
  height: 344px;
  fill: none;
}

.svg-frame:hover svg {
  transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i))) translateY(calc(-35px * var(--i)));
}

.svg-frame svg #center {
  transition: .5s;
  transform-origin: center;
}

.svg-frame:hover svg #center {
  transform: rotate(-30deg) translateX(45px) translateY(-3px);
}

#out2 {
  animation: rotate16 7s ease-in-out infinite alternate;
  transform-origin: center;
}

#out3 {
  animation: rotate16 3s ease-in-out infinite alternate;
  transform-origin: center;
  stroke: white;
}

#inner3,
#inner1 {
  animation: rotate16 4s ease-in-out infinite alternate;
  transform-origin: center;
}

#center1 {
  fill: white;
  animation: rotate16 2s ease-in-out infinite alternate;
  transform-origin: center;
}

@keyframes rotate16 {
  to {
    transform: rotate(360deg);
  }
}